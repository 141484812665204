import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"

const Iframe = ({ id }) => {
  const [iframe, setIframe] = useState({ html: "" })
  useEffect(() => {
    async function fetchData() {
      const resp = await fetch(
        `https://vimeo.com/api/oembed.json?url=https://player.vimeo.com/video/${id}&background=1&autoplay=1&loop=1&autopause=0`
      )
      const data = await resp.json()
      setIframe(data)
    }
    fetchData()
  }, [id])

  return (
    <div
      className="video-container shadow-2xl"
      dangerouslySetInnerHTML={{ __html: iframe.html }}
    />
  )
}

export default function Visuals({ project }) {
  return (
    <section className="relative py-12 md:py-20 bg-blueGradientInverted">
      <div className="container">
        <div className="flex justify-between flex-wrap gap-5 lg:flex-row md:flex-row lg:items-center md:items-center flex-col">
          <h2>Visual Exploration & Discovery</h2>
          <Link
            to="/Technology"
            className="text-sm text-purple-600 font-medium"
          >
            <FontAwesomeIcon className="mr-2" icon={faArrowRight} />
            Discover our tech
          </Link>
        </div>
        <ul className="grid lg:gap-36 md:gap-24 gap-8 lg:mt-24 md:mt-16 mt-12">
          {project.visuals.map((i, k) => {
            return (
              <li
                className={`flex lg:flex-row flex-col ${
                  k % 2 ? "lg:flex-row-reverse" : null
                }`}
                key={i.text}
              >
                {i.img && (
                  <img
                    className="lg:max-w-xl md:max-w-xl self-start shadow-2xl object-contain"
                    alt={i.text}
                    src={
                      "https://storage.googleapis.com/keshif-website/Projects/" +
                      project.slug +
                      "/" +
                      i.img
                    }
                  />
                )}
                {i.video && (
                  <video
                    className="max-w-xl w-full shadow-2xl"
                    autoPlay
                    muted
                    loop
                    playsInline
                    controls
                    src={
                      "https://storage.googleapis.com/keshif-website/Projects/" +
                      project.slug +
                      "/" +
                      i.video
                    }
                  ></video>
                )}
                {i.vimeoID && <Iframe id={i.vimeoID} />}
                <h4
                  className={`pt-12 lg:max-w-xs w-full mx-auto flex-1 self-center
                  ${
                    k % 2
                      ? "lg:pr-5 lg:border-r lg:text-right text-left"
                      : "lg:pl-5 lg:border-l"
                  }`}
                >
                  {i.text}
                </h4>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  )
}
