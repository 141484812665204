import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft } from "@fortawesome/pro-duotone-svg-icons"

export default function Testimotional({
  testimonial,
  bgColor = "bg-gray-100",
}) {
  return (
    <section className={`relative ${bgColor} py-12 md:py-20`}>
      <div className="container">
        <article className="max-w-3xl mx-auto flex">
          <FontAwesomeIcon
            size="3x"
            className="text-purple-600 mr-8"
            icon={faQuoteLeft}
          />
          <div>
            <h4 className="font-light">“{testimonial.text}”</h4>
            <h5 className="mt-6">
              <span className="font-medium">{testimonial.owner}</span>,{" "}
              {testimonial.title}
            </h5>
          </div>
        </article>
      </div>
    </section>
  )
}
