import React from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons"
import Icon from "../../components/icons/services"

export default function Services({ services, bgColor = "bg-blueGradient" }) {
  return (
    <section className={`relative ${bgColor} py-12 md:py-20`}>
      <div className="container relative flex flex-col">
        <img
          draggable="false"
          className="lg:block md:block hidden absolute right-0 -top-20 pointer-events-none"
          alt="Section Pattern"
          src="https://storage.googleapis.com/keshif-website/Illustrations/Pattern.svg"
        />
        <div className="flex justify-between flex-wrap gap-5 lg:flex-row md:flex-row lg:items-center md:items-center flex-col">
          <h2>Services</h2>
          <Link to="/Services" className="text-sm text-purple-600 font-medium">
            <FontAwesomeIcon className="mr-2" icon={faArrowRight} />
            View our services
          </Link>
        </div>
        <div
          className={`${
            services.length > 4 ? "max-w-5xl" : "max-w-3xl"
          } pt-12 z-2`}
        >
          <div
            className={`grid ${
              services.length > 4
                ? "lg:grid-cols-3 md:grid-cols-3"
                : "lg:grid-cols-2 md:grid-cols-2"
            } gap-x-5 gap-y-12`}
          >
            {services.map((i, k) => (
              <div key={i.title} className="grid gap-2 content-start">
                <div className="w-12 h-12 bg-purple-100 rounded-full grid place-items-center">
                  <Icon icon={i.icon || i.title || "none"} />
                </div>
                <h5 className="font-medium">{i.title}</h5>
                <p>{i.description}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
