import React from "react"

export default function Impact({ impact, slug }) {
  return (
    <section id="section" className="relative lg:mb-16 mb-8">
      <div className="relative ">
        <div className="relative h-56  sm:h-72 md:absolute md:left-0 md:h-full md:w-1/2">
          <img
            className="w-full h-full object-cover"
            src={`https://storage.googleapis.com/keshif-website/Projects/${slug}/Impact.png`}
            alt=""
          />
          <div
            aria-hidden="true"
            className="absolute inset-0 bg-gradient-to-r from-teal-500 to-cyan-600 mix-blend-multiply"
          />
        </div>
        <div className="relative mx-auto px-4 py-12 sm:max-w-7xl sm:px-6 sm:py-20 md:py-28 lg:px-8 lg:py-32">
          <div className="md:ml-auto md:w-1/2 md:pl-28">
            <h1 className="mb-10">Impact</h1>
            <p dangerouslySetInnerHTML={{ __html: impact.description }}></p>
            <div className="grid lg:grid-cols-3 grid-cols-2 mt-14 gap-4 lg:max-w-lg mx-auto">
              {impact.numbers.map(i => (
                <div
                  key={i.text}
                  className="block max-w-max border-l border-gray-200"
                >
                  <h2 className="font-medium text-purple-600 ml-5 mb-1">
                    {i.value}
                  </h2>
                  <p className="subtitle ml-5">{i.text.toUpperCase()}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
